<template>
	<!-- <div class="d-flex align-items-center group-content"> -->
	<div class="d-flex align-items-center">
		<b-form-input id="purchasingPrice" v-model="localPrice" type="number" style="width: 80px" class="text-right"></b-form-input
		><span class="ml-1">€</span>
	</div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
	name: 'ColorPurchasingPrice',
	components: { BFormInput },
	props: {
		color: { type: Object, required: true },
	},
	computed: {
		localPrice: {
			get() {
				if (this.color.hexValue === 'C099') console.log(this.color)
				return this.color.purchasingPrice
			},
			set(val) {
				this.$store.dispatch('ExternalProducts/updateImportColorVariantV2', {
					variantId: this.color._id,
					key: 'purchasingPrice',
					value: val,
				})
			},
		},
	},
}
</script>
