<template>
	<div v-if="importProduct">
		<b-row>
			<b-col cols="2" class="mt-3 mb-2 text-center">
				<b-img
					fluid
					:src="
						importProduct.shopName === 'l-shop' && !importProduct.mainImage.match(AWS_IMAGES_BASE_URL)
							? `${L_SHOP_IMAGES_BASE_URL}/${importProduct.mainImage}`
							: importProduct.mainImage
					"
				/>
				<b-button
					size="sm"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					variant="outline-primary"
					class="btn-icon rounded-circle"
					@click="mainImageUploadModal = true"
				>
					<feather-icon icon="Edit2Icon" />
				</b-button>
				<FileUploadModal
					:modal="mainImageUploadModal"
					type="main"
					:productName="importProduct.name"
					:closeFn="() => (this.mainImageUploadModal = false)"
					:refreshFn="
						() => {
							console.log('refresh')
						}
					"
				/>
			</b-col>
			<b-col cols="10" class="mt-3 mb-2">
				<b-form-input v-model="importProduct.name" placeholder="Produkttitel" style="width: 600px; max-width: 100%"></b-form-input>
				<div class="mt-1">
					<small class="text-muted">Shop: </small>
					<small class="text-capitalize">{{ importProduct.shopName }}</small>
				</div>
				<div>
					<small class="text-muted">Hersteller: </small> <small class="text-capitalize">{{ importProduct.manufacturer }}</small>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" class="mb-2">
				<b-form-group label="Sichtbarkeit" label-for="visible">
					<b-form-checkbox id="visible" v-model="visible"> Produkt wird im Konfigurator anzeigt </b-form-checkbox>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row v-if="isManualProduct">
			<b-col cols="6">
				<b-form-group label="Hersteller">
					<b-form-input v-model="manufacturer" placeholder="Hersteller"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="6">
				<b-form-group label="Shop">
					<b-form-input v-model="shopName" placeholder="Shop"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group label="Grammar">
					<b-form-input v-model="grammar" placeholder="Grammar"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group label="Stück pro Karton">
					<b-form-input v-model="piecesPerBox" placeholder="Stück pro Karton"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group label="Produktionsland">
					<b-form-input v-model="productionCountry" placeholder="Produktionsland"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group label="HS-Code">
					<b-form-input v-model="hsCode" placeholder="HS-Code"></b-form-input>
				</b-form-group>
			</b-col>
			<b-col cols="3">
				<b-form-group label="Gewicht">
					<b-form-input v-model="weight" placeholder="Gewicht"></b-form-input>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Beschreibung" label-for="description">
					<b-form-textarea
						id="description"
						v-model="description"
						placeholder="Beschreibung"
						rows="3"
						max-rows="3"
					></b-form-textarea>
				</b-form-group>
			</b-col>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Zertifikate" label-for="certificates">
					<b-form-textarea
						id="certificates"
						v-model="certificates"
						placeholder="Zertifikate"
						rows="3"
						max-rows="3"
					></b-form-textarea>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Material" label-for="material">
					<b-form-textarea id="material" v-model="materials" placeholder="Material" rows="3" max-rows="3"></b-form-textarea>
				</b-form-group>
			</b-col>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Produkt-Label (optional)" label-for="productLabel">
					<b-form-textarea id="productLabel" v-model="label" placeholder="Produkt-Label" rows="3" max-rows="3"></b-form-textarea>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Stil" label-for="style" :invalid-feedback="styleFeedback" :state="styleState">
					<b-form-select id="style" v-model="style" :options="styleOptions" size="md"></b-form-select>
				</b-form-group>
			</b-col>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Kategorie" label-for="category" :invalid-feedback="categoryFeedback" :state="categoryState">
					<b-form-select id="category" v-model="category" :options="categoryOptions" size="md"></b-form-select>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Mockup-Typ" label-for="productType" :invalid-feedback="productTypeFeedback" :state="productTypeState">
					<b-form-select id="productType" v-model="productType" :options="productTypeOptions" size="md"></b-form-select>
				</b-form-group>
			</b-col>
			<b-col cols="6" class="mb-2">
				<b-form-group label="Geschlecht" label-for="productType" :invalid-feedback="sexCategoryFeedback" :state="sexCategoryState">
					<b-form-select id="productType" v-model="sexCategory" :options="sexCategoryOptions" size="md"></b-form-select>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BFormSelect, BFormTextarea, BFormGroup, BRow, BCol, BImg, BFormInput, BButton, BFormCheckbox } from 'bootstrap-vue'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'

import FileUploadModal from './partials/FileUploadModal'

export default {
	name: 'ProductInfos',
	components: {
		BFormSelect,
		BFormTextarea,
		BFormGroup,
		BRow,
		BCol,
		BImg,
		BFormGroup,
		BFormInput,
		BButton,
		BFormCheckbox,
		FileUploadModal,
	},
	directives: {
		Ripple,
	},
	computed: {
		...mapFields('ExternalProducts', {
			importProduct: 'importProduct',
			description: 'importProduct.description',
			certificates: 'importProduct.certificates',
			materials: 'importProduct.materials',
			label: 'importProduct.label',
			style: 'importProduct.style',
			category: 'importProduct.category',
			productType: 'importProduct.productType',
			visible: 'importProduct.visible',
			sexCategory: 'importProduct.sexCategory',
			grammar: 'importProduct.grammar',
			piecesPerBox: 'importProduct.piecesPerBox',
			productionCountry: 'importProduct.productionCountry',
			hsCode: 'importProduct.hsCode',
			weight: 'importProduct.weight',
			manufacturer: 'importProduct.manufacturer',
			shopName: 'importProduct.shopName',
			isManualProduct: 'isManualProduct',
		}),
		...mapGetters({
			allCategories: 'InternalProducts/getCategories',
		}),
		selectedColors: {
			get() {
				const colorsArray = this.$store.getters['ExternalProducts/getSelectedImportColors']
				const colorsObj = {}
				for (let color of colorsArray) {
					colorsObj[color.value] = color.hexValue
				}
				return colorsObj
			},
			set(colorsObj) {
				const colorsArray = []
				for (let color in colorsObj) {
					colorsArray.push({ value: color, hexValue: colorsObj[color] })
				}
				this.$store.dispatch('ExternalProducts/setImportColors', colorsArray)
			},
		},
		selectedSizes: {
			get() {
				const sizesArray = this.$store.getters['ExternalProducts/getSelectedImportSizes']
				const sizesObj = {}
				for (let size of sizesArray) {
					sizesObj[size.value] = true
				}
				return sizesObj
			},
			set(sizesObj) {
				const sizesArray = []
				for (let size in sizesObj) {
					sizesArray.push({ value: size })
				}
				this.$store.dispatch('ExternalProducts/setImportSizes', sizesArray)
			},
		},
		styleState() {
			return this.style !== null && this.style.length > 0
		},
		styleFeedback() {
			if (this.style === null || this.style.length === 0) {
				return 'Wähle einen Stil aus.'
			}
			return 'Wähle einen Stil aus.'
		},
		categoryState() {
			return this.category !== null && this.category.length > 0
		},
		categoryFeedback() {
			if (this.category === null || this.category.length === 0) {
				return 'Wähle eine Kategorie aus.'
			}
			return 'Wähle eine Kategorie aus.'
		},
		productTypeState() {
			return this.productType !== null && this.productType.length > 0
		},
		productTypeFeedback() {
			if (this.productType === null || this.productType.length === 0) {
				return 'Wähle einen Mockup-Typ aus.'
			}
			return 'Wähle einen Mockup-Typ aus.'
		},
		sexCategoryState() {
			return this.sexCategory !== null && this.sexCategory.length > 0
		},
		sexCategoryFeedback() {
			if (this.sexCategory === null || this.sexCategory.length === 0) {
				return 'Wähle ein Geschlecht aus.'
			}
			return 'Wähle ein Geschlecht aus.'
		},
		categoryOptions() {
			const cats = this.allCategories.map((c) => {
				return { text: c.title, value: c.code }
			})
			cats.unshift({ value: null, text: 'Bitte wähle eine Option..' })
			return cats
		},
	},
	data() {
		return {
			mainImageUploadModal: false,
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			L_SHOP_IMAGES_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/images',
			AWS_IMAGES_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL,
			styleOptions: [
				{ value: null, text: 'Bitte wähle eine Option..' },
				{ text: 'Standard', value: 'standard' },
				{ text: 'Slim Fit', value: 'slim-fit' },
				{ text: "90'ies", value: '90ies' },
				{ text: 'Modern', value: 'modern' },
			],
			// categoryOptions: [
			// 	{ value: null, text: 'Bitte wähle eine Option..' },
			// 	{ text: 'Shirt', value: 'shirt' },
			// 	{ text: 'Hoodie', value: 'hoodie' },
			// 	{ text: 'Cap', value: 'cap' },
			// 	{ text: 'Andere', value: 'other' },
			// ],
			sexCategoryOptions: [
				{ value: null, text: 'Bitte wähle eine Option..' },
				{ text: 'Unisex', value: 'unisex' },
				{ text: 'Female', value: 'female' },
			],
			productTypeOptions: [
				{ value: null, text: 'Bitte wähle eine Option..' },
				{ text: 'T-Shirt', value: 'shirt' },
				{ text: 'T-Shirt rundhals', value: 'shirt-round-neck' },
				{ text: 'T-Shirt pocket', value: 'shirt-pocket' },
				{ text: 'Hoodie ', value: 'hoodie' },
				{ text: 'Hoodie mit Bauchtasche', value: 'hoodie-bag' },
				{ text: 'Hoodie mit Zipper', value: 'hoodie-zipper' },
				{ text: 'Longsleeve', value: 'longsleeve' },
				{ text: 'Longsleeve pocket', value: 'longsleeve-pocket' },
				{ text: 'Crewneck', value: 'crewneck' },
				{ text: 'Cap', value: 'cap' },
				{ text: 'Cap Größenverstellbar', value: 'cap-strap' },
				{ text: 'Cap Curved Snapback', value: 'cap-curved-snapback' },
				{ text: 'Cap Flat Snapback', value: 'cap-flat-snapback' },
				{ text: 'Cap Unstructured Snapback', value: 'cap-unstructured-snapback' },
				{ text: 'Cap Dad Buckle', value: 'cap-dad' },
				{ text: 'Cap Dad Belt Buckle', value: 'cap-dad-belt-buckle' },
				// { text: 'Beanie', value: 'beanie' },
				{ text: 'Polo', value: 'polo' },
				{ text: 'Custom', value: 'custom' },
				// { text: 'Socken', value: 'socks' },
				// { text: 'Hose kurz', value: 'pants-short' },
				// { text: 'Hose lang', value: 'pants-long' },
			],
		}
	},
	mounted() {
		console.log(this.importProduct)
	},
	methods: {
		selectSize(size) {
			const sizes = { ...this.selectedSizes }
			if (size in sizes) {
				delete sizes[size]
			} else {
				sizes[size] = true
			}
			this.selectedSizes = sizes
		},
		selectColor(colorObj) {
			const colors = { ...this.selectedColors }
			if (colorObj.value in colors) {
				delete colors[colorObj.value]
			} else {
				colors[colorObj.value] = colorObj.hexValue
			}
			this.selectedColors = colors
		},
	},
}
</script>

<style scoped lang="sass">
.size-button
	&.active
		background-color: #7367f0 !important
		color: white !important
.hexCode
	display: inline-block
	border-radius: 50%
	width: 20px
	height: 20px
	border: 1px solid grey
	margin-right: 3px
</style>
