<template>
	<b-form-checkbox v-model="localActiveState"> Farbe wird im Konfigurator anzeigt </b-form-checkbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
	name: 'ColorActiveState',
	components: { BFormCheckbox },
	props: {
		color: { type: Object, required: true },
	},
	computed: {
		localActiveState: {
			get() {
				return this.color.activeState
			},
			set(val) {
				this.$store.dispatch('ExternalProducts/updateImportColorVariant', {
					color: this.color.color,
					key: 'activeState',
					value: val,
				})
			},
		},
	},
}
</script>