<template>
	<b-row no-gutters>
		<b-col>
			<ProductImage :variant="variant" type="front" :isSingleImageSet="isSingleImageSet" />
		</b-col>
		<b-col>
			<ProductImage :variant="variant" type="back" :isSingleImageSet="isSingleImageSet" />
		</b-col>
		<b-col>
			<ProductImage :variant="variant" type="side" :isSingleImageSet="isSingleImageSet" />
		</b-col>
		<b-col>
			<ProductImage :variant="variant" type="detail" :isSingleImageSet="isSingleImageSet" />
		</b-col>
		<b-col>
			<ProductImage :variant="variant" type="studio" :isSingleImageSet="isSingleImageSet" />
		</b-col>
	</b-row>
</template>

<script type="text/javascript">
import ProductImage from './ProductImage'
import { BRow, BCol } from 'bootstrap-vue'

export default {
	name: 'ImageRow',
	props: {
		variant: { type: Object, required: false },
		isSingleImageSet: { type: Boolean, default: false, required: false },
	},
	components: {
		ProductImage,
		BRow,
		BCol,
	},
}
</script>
