var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.getSearchResults,"fields":_vm.fields,"filter":_vm.filter,"filter-included-fields":_vm.filterOn},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(mainImage)",fn:function(ref){
var item = ref.item;
return [_c('b-img',_vm._b({attrs:{"thumbnail":"","src":item.shopName === 'l-shop' ? (_vm.L_SHOP_IMAGES_BASE_URL + "/" + (item.mainImage)) : item.mainImage}},'b-img',{ width: 100 },false))]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))])]}},{key:"cell(shopName)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"},style:({ 'white-space': 'nowrap' })},[_vm._v(_vm._s(item.shopName))])]}},{key:"cell(sizes)",fn:function(ref){
var item = ref.item;
return _vm._l((item.sizes),function(size,idx){return _c('span',{key:idx},[_vm._v(_vm._s(size.value)+" "),(idx !== Object.keys(item.sizes).length - 1)?_c('span',[_vm._v("/ ")]):_vm._e()])})}},{key:"cell(colors)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.colors.length)+" Farbe(n) ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(!_vm.alreadyImported(item.externalArticleNumber))?_c('div',{staticClass:"text-center"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"fab":"","small":"","variant":"primary","depressed":""},on:{"click":function($event){return _vm.selectProduct(item)}}},[_c('feather-icon',{attrs:{"icon":"LogInIcon"}})],1)],1):_vm._e(),(_vm.alreadyImported(item.externalArticleNumber))?_c('div',{staticClass:"text-center"},[_c('i',[_vm._v("bereits importiert")])]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"center","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }