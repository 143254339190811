<template>
	<div>
		<b-row>
			<b-col cols="8">
				<div role="group">
					<!-- <label for="search-input">Produktname oder Artikelnummer eingeben..</label> -->
					<b-form-input
						id="search-input"
						v-model="val"
						@keyup="search"
						@focus="emptyResult = false"
						ref="searchField"
						placeholder="Produktname oder Artikelnummer eingeben.."
						:disabled="searching"
					></b-form-input>
				</div>
			</b-col>
			<b-col cols="4">
				<b-button variant="primary" @click="search" v-if="!searching">Suchen</b-button>
				<div class="d-flex justify-content-center align-items-center" style="width: 100px">
					<b-spinner label="Spinning" v-if="searching"></b-spinner>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" class="mt-2">
				<b-alert show variant="warning" v-if="emptyResult">Deine Suche hatte kein Ergebniss.</b-alert>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" class="mt-2">
				<b-button variant="primary" @click="createProductManually">Produkt manuell anlegen</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BAlert, BSpinner, BCol, BRow, BFormInput, BButton } from 'bootstrap-vue'
import { mapFields } from 'vuex-map-fields'

export default {
	name: 'SearchField',
	components: { BAlert, BSpinner, BCol, BRow, BFormInput, BButton },
	props: {
		searchValue: { type: String, required: true },
		nextFn: { type: Function, required: true },
	},
	created() {
		this.focusSearchField()
	},
	computed: {
		...mapFields('ExternalProducts', {
			searching: 'searching',
		}),
	},
	data() {
		return {
			val: this.searchValue,
			emptyResult: false,
		}
	},
	methods: {
		search(ev) {
			this.emptyResult = false
			if (
				ev &&
				((ev.type === 'keyup' && ev.keyCode === 13) || ev.type === 'click') &&
				this.val &&
				this.val.replace(/\s/g, '').length
			) {
				this.$store.dispatch('ExternalProducts/searchProduct', this.val).then((res) => {
					if (res && res.notEmpty) {
						console.log('go to next step')
						this.nextFn()
					} else {
						console.log('show empty result error')
						this.emptyResult = true
					}
				})

				this.$emit('setStep', 2)
			}
		},
		focusSearchField() {
			const that = this
			that.emptyResult = false
			that.val = ''
			setTimeout(() => {
				if (that.$refs.searchField) that.$refs.searchField.focus()
			}, 250)
		},
		createProductManually() {
			this.$store.dispatch('ExternalProducts/createProductManually')
			this.nextFn(2)
		},
	},
}
</script>

<style scoped lang="sass">
.alert
	padding: 0.71rem 1rem
</style>
