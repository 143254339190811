<template>
	<div class="text-center my-2">
		<div class="pb-1">
			<span class="mr-2 text-uppercase font-weight-bold">{{ type }}</span>
			<b-button
				size="sm"
				v-ripple.400="'rgba(113, 102, 240, 0.15)'"
				variant="outline-primary"
				class="btn-icon rounded-circle"
				@click="modal = true"
			>
				<feather-icon icon="Edit2Icon" />
			</b-button>
		</div>
		<b-img fluid :src="src" v-if="render" />
		<b-spinner v-if="!render"></b-spinner>
		<FileUploadModal
			v-if="importProduct"
			:modal="modal"
			:type="type"
			:productName="importProduct.name"
			:hexValue="!isSingleImageSet ? variant.hexValue : ''"
			:closeFn="modalCloseFn"
			:refreshFn="refreshImage"
			:variantId="variant._id"
		/>
		<!-- <b-modal v-model="modal" centered title="Bild ändern" ok-only ok-variant="outline-secondary" ok-title="Abbrechen">
			<b-card-text>
				<FileUpload
					v-if="modal"
					:type="type"
					:productName="importProduct.name"
					:hexValue="variant.hexValue"
					:closeFn="closeModal"
				/>
			</b-card-text>
		</b-modal> -->
	</div>
</template>

<script>
import { BImg, BButton, BSpinner } from 'bootstrap-vue'
import { mapFields } from 'vuex-map-fields'
import Ripple from 'vue-ripple-directive'

import FileUploadModal from './FileUploadModal'

export default {
	name: 'ProductImage',
	props: {
		variant: { type: Object, required: false },
		type: { type: String, required: true },
		isSingleImageSet: { type: Boolean, required: false, default: false },
	},
	components: {
		BImg,
		BButton,
		FileUploadModal,
		BSpinner,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			L_SHOP_IMAGES_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/images',
			src: '',
			render: false,
			modal: false,
		}
	},
	computed: {
		...mapFields('ExternalProducts', {
			importProduct: 'importProduct',
			selectedImportImages: 'selectedImportImages',
			singleImageSet: 'singleImageSet',
		}),
	},
	watch: {
		selectedImportImages() {
			this.getImageUrl()
		},
	},
	mounted() {
		this.getImageUrl()
	},
	methods: {
		modalCloseFn() {
			this.modal = false
		},
		refreshImage() {
			this.getImageUrl()
		},
		getImageUrl() {
			this.render = false
			let url

			// if (this.importProduct.shopName === 'stanley-stella') {
			// 	const colorImages = this.selectedImportImages.find((ci) => ci.colorCode === this.variant.hexValue)
			// 	if (colorImages) url = colorImages[this.type]
			// } else if (this.importProduct.shopName === 'l-shop') {
			// 	const colorImages = this.selectedImportImages.find((ci) => ci.colorCode === this.variant.hexValue)
			// 	if (colorImages) {
			// 		url = colorImages[this.type]
			// 		// image is from l-shop catalog, so we need to add the l-shop image base-url
			// 		if (!url.match(process.env.VUE_APP_AWS_IMAGES_BASE_URL)) url = this.L_SHOP_IMAGES_BASE_URL + '/' + url
			// 	}
			// } else {
			// const colorImages = this.selectedImportImages.find((ci) => ci.variantId === this.variant._id)
			// if (colorImages) url = colorImages[this.type]
			// }
			const colorImages = this.selectedImportImages.find((ci) => ci.variantId === this.variant._id)
			if (colorImages) url = colorImages[this.type]

			// check url
			if (url) {
				this.$store.dispatch('ExternalProducts/urlExists', url).then((exists) => {
					console.log(exists)
					this.src = exists ? url : '/image-not-found.png'
					this.render = true
				})
			} else {
				this.src = '/image-not-found.png'
				this.render = true
			}
		},
	},
}
</script>
