<template>
	<div class="wrapper">
		<form-wizard
			color="#7367F0"
			:title="null"
			:subtitle="null"
			layout="vertical"
			finish-button-text="Submit"
			back-button-text="Zurück"
			next-button-text="Weiter"
			class="wizard-vertical product-import-wizard"
			ref="cp-wizard"
			@on-change="onChange"
		>
			<template slot="step" slot-scope="props" v-if="importingProduct">
				<wizard-step :tab="props.tab" :transition="props.transition" :key="props.tab.title" :index="props.index"> </wizard-step>
			</template>
			<!-- account datails tab -->
			<tab-content title="Suche" v-if="!isEditProduct">
				<b-row>
					<b-col cols="12" class="mb-2">
						<h5 class="mb-0">Suche {{ activeStep }}</h5>
						<small class="text-muted"> Suche nach Name oder Artikel-Nummer. </small>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="12">
						<SearchField searchValue="" :nextFn="nextStep" />
					</b-col>
				</b-row>
			</tab-content>

			<!-- personal info tab -->
			<tab-content title="Auswahl" v-if="!isEditProduct">
				<b-row>
					<b-col cols="12" class="mb-2">
						<h5 class="mb-0">Auswahl</h5>
						<small class="text-muted">Wähle das Produkt aus.</small>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="12">
						<SelectProduct :nextFn="nextStep" :prevFn="prevStep" />
					</b-col>
				</b-row>
			</tab-content>

			<!-- address -->
			<tab-content title="Informationen">
				<b-row>
					<b-col cols="12" class="mb-2">
						<h5 class="mb-0">Informationen</h5>
						<small class="text-muted">Ergänze die nötigen Informationen.</small>
					</b-col>
				</b-row>
				<ProductInfos />
			</tab-content>

			<!-- social link -->
			<tab-content title="Varianten">
				<b-row>
					<b-col cols="12" class="mb-2">
						<h5 class="mb-0">Varianten</h5>
						<small class="text-muted">Falls nötig, ändere die Bilder des Produkts.</small>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<Images v-if="renderImages" :wizardNextTab="nextStep" />
					</b-col>
				</b-row>
			</tab-content>

			<tab-content title="Variante bearbeiten">
				<b-row>
					<b-col cols="12" class="mb-2">
						<h5 class="mb-0">Variante bearbeiten</h5>
						<small class="text-muted">Bilder des Produkts.</small>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<EditVariant />
					</b-col>
				</b-row>
			</tab-content>

			<template slot="footer" slot-scope="props">
				<div class="wizard-footer-left">
					<b-button
						variant="outline-secondary"
						v-if="props.activeTabIndex > 0"
						@click="props.prevTab()"
						:disabled="importingProduct"
					>
						Zurück
					</b-button>
				</div>
				<div class="wizard-footer-right d-flex">
					<!-- <b-button
						variant="primary"
						v-if="(props.isLastStep || isEditProduct) && !importingProduct && props.activeTabIndex !== 2"
						@click="submitImport"
						:class="{ 'mr-2': isEditProduct }"
						:disabled="importingProduct"
					>
						{{ isEditProduct ? 'Speichern' : 'Fertig' }}
					</b-button> -->
					<b-button
						variant="primary"
						v-if="
							(!isManualProduct && props.activeTabIndex === 1) ||
							(!isManualProduct && !isEditProduct && props.activeTabIndex === 3) ||
							(isManualProduct && props.activeTabIndex === 3) ||
							(isManualProduct && isEditProduct && props.activeTabIndex === 1)
						"
						@click="submitImport"
						:class="{ 'mr-2': isEditProduct }"
						:disabled="importingProduct"
					>
						{{ isEditProduct ? 'Änderungen Speichern' : 'Produkt anlegen' }}
					</b-button>

					<div v-if="importingProduct" style="display: inline-block">
						<div class="h-100 d-flex justify-content-center align-items-center" style="width: 100px">
							<b-spinner label="Importieren..." />
						</div>
					</div>

					<b-button
						variant="outline-primary"
						v-if="
							((isEditProduct && props.activeTabIndex === 0) || (!isEditProduct && props.activeTabIndex === 2)) &&
							!props.isLastStep
						"
						@click="props.nextTab()"
					>
						Weiter
					</b-button>
					<!-- <b-button variant="outline-primary" @click="props.nextTab()"> Weiter </b-button> -->
				</div>
			</template>
		</form-wizard>
	</div>
</template>

<script>
import { FormWizard, TabContent, WizardStep } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BSpinner } from 'bootstrap-vue'
import { mapFields } from 'vuex-map-fields'

// steps
import SearchField from './SearchField'
import SelectProduct from './SelectProduct'
import ProductInfos from './ProductInfos'
import Images from './Images'
import EditVariant from './EditVariant'

export default {
	name: 'Wizard',
	components: {
		FormWizard,
		TabContent,
		WizardStep,
		BButton,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BSpinner,
		vSelect,
		SearchField,
		SelectProduct,
		ProductInfos,
		Images,
		EditVariant,
	},
	props: {
		closeFn: { type: Function, required: true },
	},
	computed: {
		...mapFields('ExternalProducts', {
			importProduct: 'importProduct',
			selectedImportColors: 'selectedImportColors',
			selectedImportSizes: 'selectedImportSizes',
			selectedImportImages: 'selectedImportImages',
			isEditProduct: 'isEditProduct',
			isManualProduct: 'isManualProduct',
			isSingleImageSet: 'isSingleImageSet',
			singleImageSet: 'singleImageSet',
		}),
		activeStep() {
			console.log(this.$refs)
			return this.$refs['cp-wizard'] ? this.$refs['cp-wizard'].activeTabIndex : -1
		},
	},
	data() {
		return {
			importingProduct: false,
			renderImages: false,
		}
	},
	methods: {
		onChange(prev, next) {
			if (next === 3 && !this.isEditProduct) this.renderImages = true
			else if (next === 1 && this.isEditProduct) this.renderImages = true
			else this.renderImages = false
		},
		nextStep(stepNumber) {
			if (!stepNumber) this.$refs['cp-wizard'].nextTab()
			else {
				console.log(this.$refs['cp-wizard'])
				this.$refs['cp-wizard'].changeTab(this.$refs['cp-wizard'].activeStep, stepNumber)
			}
		},
		prevStep() {
			this.$refs['cp-wizard'].prevTab()
		},
		submitImport() {
			const data = {
				product: this.importProduct,
			}

			this.importingProduct = true
			const filterValue = data.product.name
			const that = this

			if (!this.isEditProduct || (!this.isEditProduct && this.isManualProduct)) {
				// add isManualProduct flag if product is manual
				if (this.isManualProduct) data.product.isManualProduct = true

				this.$store.dispatch('InternalProducts/createProduct', data).then(function () {
					that.importingProduct = false
					that.closeFn(filterValue)
				})
			} else {
				console.log(data)
				this.$store
					.dispatch('InternalProducts/updateProduct', { productId: this.importProduct._id, updateData: data })
					.then(function () {
						that.importingProduct = false
						that.closeFn(filterValue)
					})
			}
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="sass" scoped>
.wrapper
	height: 100%
</style>

<style lang="sass">
.product-import-wizard
	height: 100%

	.wizard-navigation
		height: calc(100% - 52px) !important

	.wizard-tab-content
		height: 100%
		position: relative

	.wizard-tab-container
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		overflow-y: auto
		padding: 30px 20px 10px
</style>
