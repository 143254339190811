<template>
	<Wizard :closeFn="closeFn" />
</template>

<script>
import Wizard from './partials/Wizard.vue'
export default {
	name: 'CreateProduct',
	components: { Wizard },
	props: {
		closeFn: { type: Function, required: true },
	},
}
</script>