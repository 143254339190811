<template>
	<b-container>
		<b-row>
			<b-col md="4" sm="6" class="my-1">
				<b-form-group class="mb-0" v-if="!orderMode">
					<label class="d-inline-block text-sm-left mr-50">Einträge pro Seite</label>
					<b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
				</b-form-group>
			</b-col>
			<b-col md="4" class="my-1">
				<b-form-group
					label=""
					label-cols-sm="0"
					label-align-sm="right"
					label-size="xs"
					label-for="filterInput"
					class="mb-0"
					v-if="!orderMode"
				>
					<b-input-group size="sm">
						<b-form-input id="filterInput" v-model="filter" type="search" placeholder="Suche.." />
						<b-input-group-append>
							<b-button :disabled="!filter" @click="filter = ''"> Löschen </b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="4" class="my-1 text-right">
				<b-button @click="createProductDialog = true" variant="gradient-primary" size="sm" class="ml-2" v-if="!orderMode">
					Produkt hinzufügen
				</b-button>

				<b-button @click="orderMode = !orderMode" variant="gradient-secondary" size="sm" class="ml-2">
					{{ orderMode ? 'Produkt-Liste' : 'Produkte sortieren' }}
				</b-button>
			</b-col>

			<SortProducts v-if="orderMode" :categories="categories" :allProducts="allProducts" />

			<!-- <div v-if="!orderMode"> -->
			<b-col cols="12" v-if="!orderMode">
				<b-table
					striped
					hover
					responsive
					:per-page="perPage"
					:current-page="currentPage"
					:items="allProducts"
					:fields="fields"
					:filter="filter"
					:filter-included-fields="filterOn"
					@filtered="onFiltered"
					:busy="loading"
				>
					<template #table-busy>
						<div class="text-center my-2 d-flex flex-column align-items-center">
							<b-spinner class="align-middle"></b-spinner>
							<strong class="mt-2">Loading...</strong>
						</div>
					</template>

					<template #cell(mainImage)="{ item }">
						<div class="text-center">
							<b-img v-bind="{ width: 100 }" thumbnail :src="item.mainImage"></b-img>
						</div>
					</template>

					<template #cell(externalArticleNumber)="{ item }">
						<b>{{ item.externalArticleNumber }}</b>
						<div>
							{{ item.shopName }}
						</div>
					</template>

					<template #cell(name)="{ item }">
						<b>{{ item.name }}</b>
					</template>

					<template #cell(style)="{ item }">
						<span style="text-transform: capitalize">
							{{ item.style }}
						</span>
					</template>

					<template #cell(category)="{ item }">
						<span style="text-transform: capitalize">
							{{ item.category }} <br />
							<!-- <small>{{ item.productType }}</small> -->
						</span>
					</template>

					<template #cell(shopName)="{ item }">
						<span style="text-transform: capitalize">{{ item.shopName }}</span>
					</template>

					<!-- <template #cell(sizes)="{ item }"> -->
					<!-- <span v-for="(size, idx) in item.sizes" :key="idx">
							{{ size.value }} <span v-if="idx !== Object.keys(item.sizes).length - 1">| </span>
						</span> -->
					<!-- </template> -->

					<template #cell(visible)="{ item }">
						<div :class="{ 'visible-circle': true, active: item.visible }"></div>
					</template>

					<template #cell(colors)="{ item }">
						<b>{{ item.colors ? item.colors.length : item.colorVariants.length }} Farbe(n)</b>
						<!-- <span v-for="(color, idx) in item.colors" :key="idx">
							<span
								v-if="item.shopName !== 'stanley-stella'"
								class="hexCode"
								:style="{
									background: '#' + color.hexValue,
								}"
							></span>
							<b-img
								:lazy-src="`${COLOR_DOT_BASE_URL}/${color.hexValue}.png`"
								contain
								height="20"
								max-width="20"
								:src="`${COLOR_DOT_BASE_URL}/${color.hexValue}.png`"
								v-if="item.shopName === 'stanley-stella'"
								class="hexCode"
							>
							</b-img>
						</span> -->
					</template>
					<template #cell(actions)="{ item }">
						<div class="text-center">
							<b-row cols="2">
								<b-col>
									<b-button variant="gradient-primary" class="btn-icon rounded-circle ml-2" @click="editProduct(item)">
										<feather-icon icon="Edit2Icon" />
									</b-button>
								</b-col>
								<b-col>
									<b-button variant="gradient-danger" class="btn-icon rounded-circle ml-2" @click="showDeleteModal(item)">
										<feather-icon icon="Trash2Icon" /> </b-button
								></b-col>
							</b-row>
						</div>
					</template>
				</b-table>
			</b-col>

			<b-col cols="12" v-if="!orderMode">
				<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
			</b-col>
			<!-- </div> -->
		</b-row>
		<b-modal
			v-model="deleteModal"
			ok-variant="danger"
			ok-title="Löschen"
			cancel-title="Abbrechen"
			cancel-variant="outline-secondary"
			@ok="deleteProduct"
			@cancel="closeDeleteModal"
			modal-class="modal-danger"
			centered
			title="Produkt löschen?"
		>
			<b-card-text>
				Produkt wirklich löschen? <br />
				Diese Aktion kann nicht rückgängig gemacht werden.
			</b-card-text>
		</b-modal>

		<b-modal
			v-model="validatingEditModal"
			modal-class="modal-warning validating-edit-modal"
			centered
			no-close-on-backdrop
			no-close-on-esc
			title="Überprüfe Daten.."
			@close="handleClose"
			@hidden="handleClose"
		>
			<b-card-text class="text-center">
				<b-spinner>Überprüfe Daten..</b-spinner>
			</b-card-text>
			<template #modal-footer>
				<span></span>
			</template>
		</b-modal>

		<b-modal
			class="create-product-modal"
			v-model="createProductDialog"
			size="xl"
			hide-footer
			modal-class="create-product-modal"
			title="Neues Produkt"
		>
			<CreateProduct :closeFn="closeCreateProductModal" />
		</b-modal>
	</b-container>
</template>

<script>
import {
	BTable,
	BRow,
	BCol,
	BFormGroup,
	BFormSelect,
	BPagination,
	BInputGroup,
	BFormInput,
	BInputGroupAppend,
	BButton,
	BContainer,
	BFormTextarea,
	BCardText,
	BFormTags,
	BImg,
	BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import CreateProduct from './CreateProduct'

import { mapFields } from 'vuex-map-fields'

import SortProducts from './partials/SortProducts'

export default {
	name: 'Products',
	components: {
		BTable,
		BContainer,
		BRow,
		BCol,
		BFormGroup,
		BFormSelect,
		BPagination,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BButton,
		BFormTextarea,
		BCardText,
		BFormTags,
		BImg,
		CreateProduct,
		BSpinner,
		SortProducts,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			orderMode: false,
			loading: false,
			editEntryId: null,
			deleteModal: false,
			deleteItem: null,
			deleting: false,
			createProductDialog: false,
			perPage: 10,
			pageOptions: [10, 20, 50],
			totalRows: 1,
			currentPage: 1,
			filter: null,
			validatingEditModal: false,
			filterOn: ['name', 'externalArticleNumber', 'shopName', 'label', 'style', 'category'],
			fields: [
				{ label: 'Bild', key: 'mainImage', tdClass: 'table-cell-no-padding' },
				{ label: 'Name', key: 'name', sortable: true },
				{ label: 'Art.Nr.', key: 'externalArticleNumber', sortable: true },
				// { label: 'Shop', key: 'shopName', sortable: true },
				// { label: 'Größen', key: 'sizes', sortable: true },
				{ label: 'Farben', key: 'colors', tdClass: 'table-cell-no-padding' },
				// { label: 'Label', key: 'label', sortable: true },
				// { label: 'Stil', key: 'style', sortable: true },
				{ label: 'Kategorie', key: 'category', sortable: true },
				{ label: 'Aktiv', key: 'visible', sortable: true },
				// { label: 'Frontend-Schlüssel', key: 'frontendKey', sortable: true, thStyle: { width: '30%' } },
				// { label: 'Text', key: 'value', sortable: true, thStyle: { width: '55%' } },
				{ label: '', key: 'actions', thStyle: { width: '15%' } },
			],
		}
	},
	computed: {
		...mapFields('InternalProducts', {
			categories: 'categories',
			allProducts: 'internalProducts',
		}),
	},
	watch: {
		allProducts(newValue) {
			this.totalRows = newValue.length
		},
		createProductDialog(newState) {
			if (!newState) {
				this.$store.dispatch('ExternalProducts/resetImportData')
			}
		},
	},
	created() {
		this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')

		// fetch products
		this.loading = true
		this.$store.dispatch('InternalProducts/fetchProducts').finally(() => (this.loading = false))
	},
	mounted() {
		// Set the initial number of items
		this.totalRows = this.allProducts.length
	},
	methods: {
		handleClose(bvModalEvt) {
			bvModalEvt.preventDefault()
		},
		closeCreateProductModal(filterValue) {
			if (filterValue) this.filter = filterValue
			this.createProductDialog = false
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		showDeleteModal(product) {
			this.deleteItem = product
			this.deleteModal = true
		},
		closeDeleteModal() {
			this.deleteDialog = false
		},
		deleteProduct() {
			this.$store.dispatch('InternalProducts/deleteProduct', this.deleteItem._id)
		},
		editProduct(product) {
			this.validatingEditModal = true
			this.$store
				.dispatch('ExternalProducts/searchProductForEdit', {
					externalArticleNumber: product.externalArticleNumber,
					shopName: product.shopName,
					product,
				})
				.then((res) => {
					this.validatingEditModal = false
					if (res && res.error) {
						console.error(res.error)
					}
					if (res && res.success) {
						// show modal
						console.info('show modal')
						this.createProductDialog = true
					}
				})
				.catch((err) => {
					this.validatingEditModal = false
					console.error(err)
				})
		},
	},
}
</script>

<style lang="sass">
.table-cell-no-padding
	padding: 0 !important
.create-product-modal .modal-dialog
	max-width: 100%
	margin: 0
	top: 20px
	bottom: 20px
	left: 20px
	right: 20px
	height: calc(100vh - 40px)
	display: flex
	position: fixed
	z-index: 100000
.validating-edit-modal
	button.close
		display: none
	.modal-footer
		border: none
		padding: 0
		height: 0
</style>

<style scoped lang="sass">
.hexCode
	display: inline-block
	border-radius: 50%
	width: 20px
	height: 20px
	border: 1px solid grey
	margin-right: 3px
.visible-circle
	width: 10px
	height: 10px
	border-radius: 50%
	background: #ea5455
	&.active
		background: #6D940B
</style>