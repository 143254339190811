<template>
	<div id="import-product-images">
		<!-- <div>
			<b-form-group label="" label-for="isSingleImageSet">
				<b-form-checkbox id="isSingleImageSet" v-model="isSingleImageSet"> ein Bilder-Set für alle Farben </b-form-checkbox>
			</b-form-group>
		</div> -->
		<div class="import-image-upload">
			<div class="mb-12 pl-0 pr-0 elevation-0" v-if="importProduct && !isSingleImageSet">
				<!-- <div v-for="(color, idx) in selectedColors" :key="idx" class="color-image mt-10 mb-10"> -->
				<!-- <div v-for="(color, idx) in importProduct.colorVariants" :key="idx" class="color-image mt-10 mb-10"> -->
				<div class="table-header">
					<div class="d-flex justify-content-between align-items-center">
						<div class="custom-col name-col">
							<b-form-group label="Name" label-for="visible"> </b-form-group>
						</div>
						<div class="custom-col visibility-col">
							<b-form-group label="Sichtbarkeit" label-for="visible"> </b-form-group>
						</div>
						<div class="custom-col price-col">
							<b-form-group label="Einkaufspreis" label-for="purchasingPrice"> </b-form-group>
						</div>
						<div class="custom-col action-col"></div>
					</div>
				</div>
				<div class="table-content">
					<div v-for="(color, idx) in importProduct.colors" :key="idx" class="color-image mt-10 mb-10">
						<div>
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex align-items-center custom-col name-col">
									<span
										v-if="importProduct.shopName !== 'stanley-stella'"
										class="hexCode-circle"
										:style="{
											background: '#' + color.hexValue,
										}"
									></span>

									<b-img
										v-bind="{ maxWidth: 40, height: 40 }"
										:src="`${COLOR_DOT_BASE_URL}/${color.hexValue}.png`"
										v-if="importProduct.shopName === 'stanley-stella'"
										class="hexCode"
									>
									</b-img>
									<!-- <h4 class="ml-2" style="display: inline">{{ color.value }}</h4> -->
									<h4 class="ml-2 mb-0" style="display: inline">{{ color.color }}</h4>
								</div>
								<div class="custom-col visibility-col">
									<!-- <b-form-group label="Sichtbarkeit" label-for="visible"> -->
									<div class="d-flex align-items-center">
										<ColorActiveState :color="color" />
									</div>
									<!-- </b-form-group> -->
								</div>
								<div class="custom-col price-col">
									<!-- <b-form-group label="Einkaufspreis" label-for="purchasingPrice"> -->
									<ColorPurchasingPrice :color="color" />
									<!-- </b-form-group> -->
								</div>
								<div class="custom-col action-col">
									<b-button
										size="sm"
										:class="['mr-1', 'size-button']"
										@click="() => editColors(color._id)"
										variant="outline-primary"
									>
										Edit
									</b-button>
									<b-button
										size="sm"
										class="mt-1"
										@click="() => deleteVariant(color._id)"
										v-if="isManualProduct"
										variant="outline-danger"
									>
										Delete
									</b-button>
								</div>
							</div>
						</div>
						<div class="divider"></div>
					</div>
				</div>
				<div v-if="isManualProduct">
					<b-button @click="addVariant">+ Variante hinzufügen</b-button>
				</div>
			</div>
			<div class="mb-12 pl-0 pr-0 elevation-0" v-else>
				<div>
					<div class="ma-2 mt-4">
						<h4 class="ml-2" style="display: inline">Bilder-Set</h4>
					</div>
					<ImageRow :isSingleImageSet="true" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { BImg, BFormCheckbox, BFormGroup, BButton, BRow, BCol, BFormInput, BIcon } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'

import ImageRow from './partials/ImageRow'
import ColorSize from './partials/ColorSize'
import ColorActiveState from './partials/ColorActiveState'
import ColorPurchasingPrice from './partials/ColorPurchasingPrice'

export default {
	name: 'Images',
	props: {
		wizardNextTab: { type: Function, required: true },
	},
	components: {
		BImg,
		ImageRow,
		BFormCheckbox,
		BFormGroup,
		BButton,
		BRow,
		BCol,
		BFormInput,
		BIcon,
		ColorSize,
		ColorActiveState,
		ColorPurchasingPrice,
	},
	computed: {
		...mapFields('ExternalProducts', {
			importProduct: 'importProduct',
			isSingleImageSet: 'isSingleImageSet',
			isManualProduct: 'isManualProduct',
		}),
		...mapGetters({
			selectedColors: 'ExternalProducts/getSelectedImportColors',
			selectedSizes: 'ExternalProducts/getSelectedImportSizes',
		}),
	},
	data: function () {
		return {
			myFiles: ['cat.jpeg'],
			activatedUploads: [],
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			L_SHOP_IMAGES_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/images',
		}
	},
	mounted() {
		this.$store.dispatch('ExternalProducts/setSelectedVariant', null)
	},
	methods: {
		editColors(variantId) {
			console.log(variantId)
			this.$store.dispatch('ExternalProducts/setSelectedVariant', variantId)
			this.wizardNextTab()
		},
		addVariant() {
			this.$store.dispatch('ExternalProducts/addVariant')
		},
		deleteVariant(variantId) {
			this.$store.dispatch('ExternalProducts/deleteVariant', variantId)
		},
	},
}
</script>

<style lang="sass">
#import-product-images
	.form-group
		>label
			font-weight: 700 !important
			font-size: 14px
</style>

<style scoped lang="sass">
.hexCode-circle
	width: 40px
	height: 40px
	border-radius: 50%
	border: 1px solid lightgrey

.group-content
	height: 40px
	margin-top: 15px

	&.sizes
		height: auto
		margin-left: -20px
		.size-item
			margin: 20px !important
			.info
				margin-top: 10px
				font-weight: 700
				.label
					font-size: 10px
					text-transform: uppercase
					font-weight: 500

.divider
	height: 1px
	background-color: #EBE9F1
	margin: 20px 0

.import-image-upload .color-image
	display: flex
	flex-direction: column

	& .color-box
		width: 100px
		background: grey
		color: white
		display: flex
		align-items: center
		justify-content: center

	& .upload-wrapper
		width: 400px

	.custom-col
		flex-basis: 25%
		&.name-col
			flex-basis: 40%
		&.visibility-col
			flex-basis: 35%
		&.price-col
			flex-basis: 15%
		&.action-col
			flex-basis: 10%

.table-header
	margin-bottom: 20px
	// position: absolute
	// left: 0
	// right: 0
	background: white
	.custom-col
		flex-basis: 25%
		&.name-col
			flex-basis: 40%
		&.visibility-col
			flex-basis: 35%
		&.price-col
			flex-basis: 15%
		&.action-col
			flex-basis: 10%
.table-content
	padding-top: 0px
</style>
