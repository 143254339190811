<template>
	<b-button size="sm" :class="['mr-1', 'size-button', isPressed && 'custom-active']" @click="change" variant="outline-primary">
		<b>{{ size.value }}</b>
	</b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
	name: 'ColorSize',
	components: { BButton },
	props: {
		size: { type: Object, required: true },
		color: { type: Object, required: true },
	},
	computed: {
		isPressed: {
			get() {
				return this.color.activeSizes.findIndex((as) => as.value === this.size.value) > -1
			},
			set(val) {
				let activeSizes = [...this.color.activeSizes]

				if (val) activeSizes.push(this.size)
				else {
					const idx = this.color.activeSizes.findIndex((as) => as.value === this.size.value)
					if (idx > -1) activeSizes.splice(idx, 1)
				}
				this.$store.dispatch('ExternalProducts/updateImportColorVariant', {
					color: this.color.color,
					key: 'activeSizes',
					value: activeSizes,
				})
			},
		},
	},
	methods: {
		change() {
			const val = !(this.color.activeSizes.findIndex((as) => as.value === this.size.value) > -1)
			let activeSizes = [...this.color.activeSizes]

			if (val) activeSizes.push(this.size)
			else {
				const idx = this.color.activeSizes.findIndex((as) => as.value === this.size.value)
				if (idx > -1) activeSizes.splice(idx, 1)
			}
			this.$store.dispatch('ExternalProducts/updateImportColorVariant', {
				color: this.color.color,
				key: 'activeSizes',
				value: activeSizes,
			})
		},
	},
}
</script>

<style lang="sass" scoped>
.custom-active
	background-color: #7367f0 !important
	color: white !important
</style>