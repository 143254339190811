<template>
	<b-modal v-model="modalStatus" centered title="Bild ändern" ok-only ok-variant="outline-secondary" ok-title="Abbrechen">
		<b-card-text>
			<div id="product-import-file-upload" class="text-center py-3 d-flex flex-column">
				<FileUploadPlugin
					class="btn btn-outline-primary custom-style"
					:extensions="extensions"
					:accept="accept"
					:multiple="multiple"
					:size="size || 0"
					:drop="true"
					v-model="files"
					@input-filter="inputFilter"
					@input-file="inputFile"
					ref="upload"
					v-if="!isUploading"
				>
					Datei von Computer auswählen
				</FileUploadPlugin>
				<div class="d-flex flex-column align-items-center justify-content-center" v-if="isUploading">
					<b-spinner variant="primary" />
					<div class="mt-2">Uploading..</div>
				</div>
				<!-- <div class="progress d-flex justify-content-center" v-if="isUploading">
					<div
						class="progress-bar"
						role="progressbar"
						:style="{ width: uploadProgress + '%' }"
						:aria-valuenow="uploadProgress"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div> -->
				<b-button variant="danger" class="mt-2" @click="deleteImage" v-if="!isUploading">Bild löschen</b-button>
			</div>
		</b-card-text>
		<template #modal-footer>
			<div class="w-100">
				<b-button variant="outline-primary" class="float-right" @click="closeModal"> Abbrechen </b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import FileUploadPlugin from 'vue-upload-component'
import { BButton, BModal, BCardText, BSpinner } from 'bootstrap-vue'

export default {
	name: 'FileUploadModal',
	components: { FileUploadPlugin, BButton, BModal, BCardText, BSpinner },
	props: {
		closeFn: { type: Function, required: true },
		refreshFn: { type: Function, required: true },
		modal: { type: Boolean, required: true },
		productName: { type: String, required: true },
		type: { type: String, required: true },
		hexValue: { type: String, required: false },
		variantId: { type: String, required: false },
	},
	data() {
		return {
			files: [],
			accept: 'image/png,image/gif,image/jpeg,image/webp',
			extensions: 'gif,jpg,jpeg,png,webp',
			minSize: 1024,
			size: 1024 * 1024 * 10,
			multiple: true,
			uploadProgress: 0,
			isUploading: false,
			modalStatus: false,
		}
	},
	watch: {
		modal(status) {
			// trigger modal open from outside
			this.modalStatus = status
		},
		modalStatus(status) {
			// update outside modal status if modal is closed
			if (!status) this.closeFn()
		},
	},
	methods: {
		closeModal() {
			this.modalStatus = false
		},
		inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				// Before adding a file
				// Filter system files or hide files
				if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
					return prevent()
				}

				// Filter php html js file
				if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
					return prevent()
				}
			}
		},
		inputFile(newFile, oldFile) {
			if (newFile && !oldFile) {
				// add
				console.log('add', newFile)
				this.upload(newFile.file)
			}
			// if (newFile && oldFile) {
			// 	// update
			// 	console.log('update', newFile)
			// }
			// if (!newFile && oldFile) {
			// 	// remove
			// 	console.log('remove', oldFile)
			// }
		},
		deleteImage() {
			if (this.type === 'main') {
				this.$store
					.dispatch('ExternalProducts/updateImportProduct', {
						key: 'mainImage',
						value: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
						variantId: this.variantId,
					})
					.then(() => {
						this.isUploading = false
						this.uploadProgress = 0
						this.refreshFn()
						this.closeModal()
					})
			} else {
				this.$store
					.dispatch('ExternalProducts/updateImportImage', {
						imageUrl: 'https://wercbrain-lshop-images.s3.eu-central-1.amazonaws.com/images/image-not-found.png',
						hexValue: this.hexValue,
						type: this.type,
						variantId: this.variantId,
					})
					.then(() => {
						this.isUploading = false
						this.uploadProgress = 0
						this.refreshFn()
						this.closeModal()
					})
			}
		},
		upload(file) {
			const that = this
			that.isUploading = true
			that.$store
				.dispatch('Files/simpleUpload', {
					file,
					fileName: `${that.productName.trim().replace(/([\W\s])+/gi, '-')}_${Date.now()}.${file.type.split('/')[1]}`,
				})
				.then((res) => {
					console.log(res)
					console.log(that.type)
					that.uploadProgress = 100

					if (that.type === 'main') {
						that.$store
							.dispatch('ExternalProducts/updateImportProduct', {
								key: 'mainImage',
								value: res.Location,
								variantId: that.variantId,
							})
							.then(() => {
								that.isUploading = false
								that.uploadProgress = 0
								// that.refreshFn()
								that.closeModal()
							})
					} else {
						that.$store
							.dispatch('ExternalProducts/updateImportImage', {
								imageUrl: res.Location,
								hexValue: that.hexValue,
								type: that.type,
								variantId: that.variantId,
							})
							.then(() => {
								that.isUploading = false
								that.uploadProgress = 0
								that.refreshFn()
								that.closeModal()
							})
					}
				})

			// that.s3UploadInstance.on('httpUploadProgress', function (progress) {
			// 	let progressPercentage = Math.round((progress.loaded / progress.total) * 100)
			// 	that.uploadProgress = progressPercentage
			// })
		},
	},
}
</script>

<style lang="sass">
#product-import-file-upload
	.btn-primary
		cursor: pointer
		label, input
			cursor: pointer !important
</style>
