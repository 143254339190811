<template>
	<b-container>
		<b-row>
			<!-- <b-col md="4" sm="6" class="my-1">
				<b-form-group class="mb-0">
					<label class="d-inline-block text-sm-left mr-50">Einträge pro Seite</label>
					<b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
				</b-form-group>
			</b-col>
			<b-col md="6" class="my-1">
				<b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
					<b-input-group size="sm">
						<b-form-input id="filterInput" v-model="filter" type="search" placeholder="Suche.." />
						<b-input-group-append>
							<b-button :disabled="!filter" @click="filter = ''"> Löschen </b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col md="2" class="my-1 text-center">
				<b-button @click="createProductDialog = true" variant="gradient-primary" size="sm" class="ml-2">
					Produkt hinzufügen
				</b-button>
			</b-col> -->

			<b-col cols="12">
				<b-table
					striped
					hover
					responsive
					:per-page="perPage"
					:current-page="currentPage"
					:items="getSearchResults"
					:fields="fields"
					:filter="filter"
					:filter-included-fields="filterOn"
					@filtered="onFiltered"
				>
					<template #cell(mainImage)="{ item }">
						<b-img
							v-bind="{ width: 100 }"
							thumbnail
							:src="item.shopName === 'l-shop' ? `${L_SHOP_IMAGES_BASE_URL}/${item.mainImage}` : item.mainImage"
						>
						</b-img>
					</template>

					<template #cell(name)="{ item }">
						<b>{{ item.name }}</b>
					</template>

					<template #cell(shopName)="{ item }">
						<span style="text-transform: capitalize" :style="{ 'white-space': 'nowrap' }">{{ item.shopName }}</span>
					</template>

					<template #cell(sizes)="{ item }">
						<span v-for="(size, idx) in item.sizes" :key="idx"
							>{{ size.value }} <span v-if="idx !== Object.keys(item.sizes).length - 1">/ </span></span
						>
					</template>

					<template #cell(colors)="{ item }">
						{{ item.colors.length }} Farbe(n)
						<!-- <div class="colorsWrap">
							<div v-for="(color, idx) in item.colors" :key="idx" class="colorItem">
								<span
									v-if="item.shopName !== 'stanley-stella'"
									class="hexCode"
									:style="{
										background: '#' + color.hexValue,
									}"
								></span>
								<b-img
									:lazy-src="`${COLOR_DOT_BASE_URL}/${color.hexValue}.png`"
									contain
									height="20"
									max-width="20"
									:src="`${COLOR_DOT_BASE_URL}/${color.hexValue}.png`"
									v-if="item.shopName === 'stanley-stella'"
									class="hexCode"
								>
									<template v-slot:placeholder>
										<span class="hexCode" :style="{ background: 'lightgrey' }" />
									</template>
								</b-img>
							</div>
						</div> -->
					</template>
					<template #cell(actions)="{ item }">
						<div class="text-center" v-if="!alreadyImported(item.externalArticleNumber)">
							<b-button class="btn-icon rounded-circle" fab small variant="primary" depressed @click="selectProduct(item)">
								<feather-icon icon="LogInIcon" />
							</b-button>
						</div>
						<div class="text-center" v-if="alreadyImported(item.externalArticleNumber)"><i>bereits importiert</i></div>
					</template>
				</b-table>
			</b-col>

			<b-col cols="12">
				<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="my-0" />
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import { mapGetters } from 'vuex'

import {
	BTable,
	BRow,
	BCol,
	BFormGroup,
	BFormSelect,
	BPagination,
	BInputGroup,
	BFormInput,
	BInputGroupAppend,
	BButton,
	BContainer,
	BFormTextarea,
	BCardText,
	BFormTags,
	BImg,
	BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
	name: 'SelectProduct',
	components: {
		BTable,
		BRow,
		BCol,
		BFormGroup,
		BFormSelect,
		BPagination,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BButton,
		BContainer,
		BFormTextarea,
		BCardText,
		BFormTags,
		BImg,
		BSpinner,
	},
	directives: {
		Ripple,
	},
	props: {
		nextFn: { type: Function, required: true },
		prevFn: { type: Function, required: true },
	},
	computed: {
		...mapGetters({
			getSearchResults: 'ExternalProducts/getSearchResults',
			alreadyImported: 'InternalProducts/isAvailabled',
		}),
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			L_SHOP_IMAGES_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/images',
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			loading: false,
			editEntryId: null,
			deleteDialog: false,
			deleteItem: null,
			deleting: false,
			createProductDialog: false,
			perPage: 10,
			pageOptions: [10, 20, 50],
			totalRows: 1,
			currentPage: 1,
			filter: null,
			filterOn: [],
			fields: [
				{ label: 'Bild', key: 'mainImage', tdClass: 'table-cell-no-padding' },
				{ label: 'Name', key: 'name', sortable: false },
				{ label: 'Art.Nr.', key: 'externalArticleNumber', sortable: false },
				{ label: 'Shop', key: 'shopName', sortable: false },
				{ label: 'Größen', key: 'sizes', tdClass: 'table-cell-no-padding' },
				{ label: 'Farben', key: 'colors', tdClass: 'table-cell-no-padding' },
				{ label: 'Aktionen', key: 'actions', sortable: false },
			],
		}
	},
	mounted() {
		this.totalRows = this.getSearchResults.length
	},
	methods: {
		selectProduct(product) {
			// if (product.shopName === 'l-shop') {
			// 	product.colorImages = product.colors.map((color) => {
			// 		return {
			// 			colorCode: color.hexValue,
			// 			front: this.L_SHOP_IMAGES_BASE_URL + '/' + product.mainImage,
			// 			back: this.L_SHOP_IMAGES_BASE_URL + '/' + product.mainImage,
			// 			side: this.L_SHOP_IMAGES_BASE_URL + '/' + product.mainImage,
			// 			detail: this.L_SHOP_IMAGES_BASE_URL + '/' + product.mainImage,
			// 		}
			// 	})
			// }

			this.$store.dispatch('ExternalProducts/setImportProduct', product)
			this.$store.dispatch('ExternalProducts/setImportSizes', product.sizes)
			this.$store.dispatch('ExternalProducts/setImportColors', product.colors)
			this.$store.dispatch('ExternalProducts/setImportImages', product.colors)
			console.log('next step')
			this.nextFn()
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
	},
}
</script>

<style scoped lang="sass">
.test-box
	width: 100%
	background: blue
	height: 800px
.colorsWrap
	width: 200px
	.colorItem
		display: inline-block
.hexCode
	display: inline-block
	border-radius: 50%
	width: 20px
	height: 20px
	border: 1px solid grey
	margin-right: 3px
</style>
