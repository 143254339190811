<template>
	<div>
		<b-col cols="12">
			<div style="margin: 0 0 20px">
				<b-button
					v-for="(cat, idx) in orderCategories"
					:key="idx"
					:pressed.sync="cat.active"
					variant="outline-primary"
					@click="setOrderCategory(cat.code)"
					style="margin: 0 5px"
				>
					{{ cat.title }}
				</b-button>
			</div>
		</b-col>

		<b-col cols="12">
			<draggable v-model="orderProductsArray" group="products" @start="drag = true" @end="drag = false" class="order-wrapper">
				<b-card v-for="element in orderProductsArray" :key="element._id" class="order-item">
					<div class="text-center">
						<b-img v-bind="{ height: 50 }" style="height: 50px" thumbnail :src="element.mainImage"></b-img>
					</div>
					<div>
						<div class="d-flex align-items-center justify-content-center flex-column h-100" style="padding-top: 5px">
							<b class="text-center">{{ element.name }}</b>
							<small class="text-center">{{ element.productType }}</small>
						</div>
					</div>
				</b-card>
			</draggable>
		</b-col>
	</div>
</template>

<script>
import { BCol, BButton, BImg, BCard } from 'bootstrap-vue'

import draggable from 'vuedraggable'

export default {
	name: 'OrderProducts',
	components: { draggable, BCol, BButton, BImg, BCard },
	props: {
		categories: { type: Array, required: true },
		allProducts: { type: Array, required: true },
	},
	data() {
		return {
			orderCategory: 'shirt',
		}
	},
	computed: {
		orderProductsArray: {
			get() {
				return this.allProducts
					.filter((p) => p.category === this.orderCategory)
					.sort((a, b) => {
						if (a.sortIndex < b.sortIndex) return -1
						else if (a.sortIndex > b.sortIndex) return 1
						else return 0
					})
			},
			set(value) {
				this.$store.dispatch('InternalProducts/sortProducts', { orderedProducts: value, category: this.orderCategory })
			},
		},
		orderCategories() {
			const ar = this.categories.map((cat) => {
				cat.active = cat.code === this.orderCategory
				return cat
			})
			return ar
		},
	},
	methods: {
		setOrderCategory(categoryCode) {
			this.orderCategory = categoryCode
		},
	},
}
</script>

<style scoped lang="sass">
.order-wrapper
	display: flex
	flex-direction: row
	flex-wrap: wrap
	.order-item
		width: 200px
		margin: 5px
		cursor: move
</style>