<template>
	<div v-if="variant">
		<div class="d-flex align-items-center">
			<span
				v-if="importProduct.shopName !== 'stanley-stella'"
				class="hexCode-circle"
				:style="{
					background: '#' + variant.hexValue,
				}"
			></span>

			<b-img
				v-bind="{ maxWidth: 40, height: 40 }"
				:src="`${COLOR_DOT_BASE_URL}/${variant.hexValue}.png`"
				v-if="importProduct.shopName === 'stanley-stella'"
				class="hexCode"
			>
			</b-img>
			<!-- <h2 class="ml-1 mb-0">{{ variant.value }}</h2> -->
			<b-form-input id="purchasingPrice" v-model="variant.value" type="text" style="width: 200px" class="text-right"></b-form-input>
		</div>
		<div v-if="isManualProduct" class="mt-2">
			<label for="">HEX-Farbwert</label>
			<b-form-input
				id="purchasingPrice"
				v-model="variant.hexValue"
				type="text"
				style="width: 200px"
				class="text-right"
			></b-form-input>
		</div>
		<b-row v-if="!render">
			<b-col>
				<div class="my-4 ml-1 d-flex align-items-center">
					<BSpinner></BSpinner>
					<div class="ml-1">Loading..</div>
				</div>
			</b-col>
		</b-row>
		<b-row v-if="render">
			<b-col cols="12" class="mt-2">
				<b-form-group label="Größen" label-for="visible">
					<div class="d-flex align-items-start group-content sizes flex-row flex-wrap">
						<div
							v-for="(size, sizeIdx) in variant.sizes"
							:key="sizeIdx"
							class="d-flex size-item mt-1 align-items-start flex-column"
						>
							<ColorSize :size="size" :color="variant" />
							<div class="info" v-if="size.availability">
								<div><span class="label">Stock:</span> {{ size.availability.stock }}</div>
								<div>
									<span class="label">Veröffentlicht:</span>
									{{ size.availability.published === 1 ? 'Ja' : 'Nein' }}
								</div>
								<div>
									<span class="label">Verfügbar seit</span>
									{{ formatDate(size.availability.skuStartDate) }}
								</div>
							</div>
						</div>
					</div>
				</b-form-group>
			</b-col>
			<b-col cols="12" class="mt-2">
				<b-form-group label="Bilder" label-for="visible">
					<ImageRow :variant="variant" />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'

import { BImg, BFormCheckbox, BFormGroup, BButton, BRow, BCol, BFormInput, BSpinner } from 'bootstrap-vue'
import ImageRow from './partials/ImageRow'
import ColorSize from './partials/ColorSize'
import ColorActiveState from './partials/ColorActiveState'

export default {
	name: 'EditVariant',
	components: { ImageRow, ColorSize, ColorActiveState, BImg, BFormCheckbox, BFormGroup, BButton, BRow, BCol, BFormInput, BSpinner },
	computed: {
		variant() {
			const variant = this.$store.getters['ExternalProducts/getSelectedVariant']
			if (variant) this.hexValue = variant.hexValue
			return variant
		},
		...mapFields('ExternalProducts', {
			importProduct: 'importProduct',
			isManualProduct: 'isManualProduct',
		}),
	},
	watch: {
		'variant.value'() {
			if (this.variant) this.variant.color = this.variant.value
		},
		'variant.hexValue'(newVal, oldVal) {
			console.log(oldVal)
			if (oldVal && newVal) {
				// update colorImages in store
				this.$store.dispatch('ExternalProducts/updateColorImages', {
					oldHexValue: oldVal,
					newHexValue: newVal,
				})
			}
		},
		variant: {
			handler(newVal, oldVal) {
				if ((newVal && oldVal && newVal._id && oldVal._id && newVal._id !== oldVal._id) || !oldVal) {
					this.render = false
					setTimeout(() => {
						this.render = true
					}, 200)
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			COLOR_DOT_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/stanley-stella-colors',
			L_SHOP_IMAGES_BASE_URL: process.env.VUE_APP_AWS_IMAGES_BASE_URL + '/images',
			render: false,
			hexValue: null,
		}
	},
	methods: {
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
	},
}
</script>

<style lang="sass" scoped>
.hexCode-circle
	width: 40px
	height: 40px
	border-radius: 50%
	border: 1px solid lightgrey
</style>
